.pl-styleguide {
  &__header {
    background: #073357;
    padding: 20px;
    margin-bottom: 60px;

    @include mq(l) {
      padding: 30px 120px;
    }
  }

  // Styleguide Module Display
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > h1,
    > h2,
    > h3,
    > p {
      margin: 12px 0;
    }

    &:not(&--column) {
      > div {
        margin: 0 15px;
      }
    }

    &--column {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    &--center {
      flex-direction: column;
      align-items: center;
    }

    p {
      position: relative;
      z-index: 2;
    }
  }

  // Styleguide Color
  &__color {
    width: 170px;
    height: 60px;

    &.grey-super-light,
    &.white {
      border: solid 1px #979797;
    }
  }

  // Styleguide Spacing
  &__spacing {
    max-width: 370px;
    width: 100%;
    background: #b1fbe2;
    flex-grow: 1;
    flex-basis: 0%;

    ~ p {
      margin: 0 0 0 30px;
    }
  }

  // Styleguide Grid
  &__row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin-bottom: 20px;
    background-color: get-color(petrol);
  }

  &__column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: 0;
    color: get-color(white, $colors-greyScale);
    background-color: get-color(blue-night);

    @include use-grid-gutter('margin-left', (), true);

    &:first-child {
      margin-left: 0;
    }

    &.col-1 {
      @include use-grid-span(
        'width',
        (
          m: 1 narrow
        ),
        (4/12 narrow)
      );
    }

    &.col-3 {
      @include use-grid-span(
        'width',
        (
          m: 3 narrow
        ),
        (1 narrow)
      );
    }

    &.col-4 {
      @include use-grid-span(
        'width',
        (
          m: 4 narrow
        ),
        (4/3 narrow)
      );
    }

    &.col-6 {
      @include use-grid-span(
        'width',
        (
          m: 6 narrow
        ),
        (2 narrow)
      );
    }
  }
}
