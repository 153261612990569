// 3rd Party Frameworks
@import '~sanitize.css/sanitize';
@import '~susy/sass/susy';
@import '~susy/sass/plugins/svg-grid';
@import '~sass-mq/mq';

// Settings
@import '../settings/colors';
@import '../settings/breakpoints';
@import '../settings/spacings';
@import '../settings/typo';
@import '../settings/z-index';
@import '../settings/grid';

// Tools
@import '../tools/functions';
@import '../tools/mixins';
@import '../tools/text-types';

@import 'filter-nav';
@import 'styleguide';

.pl-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #073357;
}
