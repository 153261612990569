.pl-filter-nav {
  color: #ffffff;
  font-family: helvetica, sans-serif;

  &__no-result-message {
    padding: 10px 0;
    color: rgba(#4a4a4a, 0.2);

    &--hidden {
      display: none;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__no-result-message,
  &__item {
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }

  &__item {
    &:first-child {
      border-top: 1px solid #979797;
    }

    &--hidden {
      display: none;
    }

    &-link {
      padding: 20px 12px;
      color: #979797;
      display: flex;
      align-items: center;
      text-decoration: none;

      &-title {
        flex-grow: 1;
      }

      &:hover &-title {
        color: #ffffff;
      }

      &:hover {
        svg {
          fill: #ffffff;
        }
      }

      &-state {
        display: flex;
        align-items: center;
        margin-right: 20px;

        &:after {
          border-radius: 50%;
          width: 14px;
          height: 14px;
          content: '';
          display: inline-block;
          margin-left: 4px;
        }

        &--wip {
          color: #d8072d;

          &:after {
            background-color: #d8072d;
          }
        }

        &--approved {
          color: #3bad98;

          &:after {
            background-color: #3bad98;
          }
        }

        &--draft {
          color: #ffe6a6;

          &:after {
            background-color: #ffe6a6;
          }
        }
      }
    }
  }

  &__search {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    height: 76px;

    .a-svg-sprite {
      position: absolute;
      top: 21px;
      left: 21px;
    }
  }

  &__input {
    border: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 26px;
    line-height: 50px;
    padding: 0 10px 0 68px;
    width: 100%;
    background-color: #ffffff;

    &::placeholder {
      color: #979797;
    }

    &:focus {
      outline: none;
    }

    &-label {
      display: block;
      text-align: center;
      width: 120px;
      padding: 24px 15px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #eaeaea;
      color: #07233d;
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__category-title {
    font-size: 40px;
    font-weight: normal;
    margin-bottom: 10px;
    padding: 10px 0;
  }
}
